import ApiInstance from './ApiInstance.js'

export default {

// First level categories

  getFirstLevelCategories() {
    return ApiInstance({ requiresAuth: true }).get('/first-level-category');
  },
  addNewFirstLevelCategory(Data) {
    return ApiInstance({ requiresAuth: true }).post('/first-level-category', {categories: Data});
  },
  editFirstLevelCategory(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/first-level-category/${id}`, {category: Data[0]});
  },
  deleteFirstLevelCategory(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/first-level-category/${id}`);
  },


// second level categories
    getSecondLevelCategories() {
    return ApiInstance({ requiresAuth: true }).get('/second-level-category');
  },

  addNewSecondLevelCategory(Data) {
    return ApiInstance({ requiresAuth: true }).post('/second-level-category', {categories: Data});
  },
  editSecondLevelCategory(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/second-level-category/${id}`, Data[0]);
  },
  deleteSecondLevelCategory(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/second-level-category/${id}`);
  },


  // main categories
  getCategories() {
    return ApiInstance({ requiresAuth: true }).get('/category');
  },
  addNewCategory(Data) {
    return ApiInstance({ requiresAuth: true }).post('/category', {categories: Data});
  },
  editCategory(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/category/${id}`, Data[0]);
  },
  deleteCategory(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/category/${id}`);
  }
}
